import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { Field, ErrorMessage } from 'formik';
import { shuffle } from '../../helpers';
import amplitude from 'amplitude-js';

const Select = (data) => {
  let { attributes, errors, touched, setValues, formRef, values, isActive } =
    data;
  let {
    className,
    innerClassName,
    name,
    opts,
    label,
    isRequired,
    dependency,
    isDependentField,
    onMount,
    showError,
    onSelectChange,
    onSelectChangeDeprecated,
    randomizeOptions,
  } = attributes;

  let [optKeys, setOptKeys] = useState(opts && Object.keys(opts));

  const inputEl = useRef(null);

  const onChange = (e) => {
    if (onSelectChange) {
      typeof onSelectChange === 'string'
        ? new Function('values', onSelectChange)({ e, setValues, values })
        : onSelectChange(e);
    }

    if (onSelectChangeDeprecated) {
      onSelectChangeDeprecated(e);
    }

    if (values) {
      setValues(Object.assign(values, { [name]: e.target.value }));
    }
  };

  const revealDependentField = (e, isOnLoad) => {
    if (dependency) {
      let operators = {
        '=': (a, b) => a === b,
        '<': (a, b) => a < b,
        '<=': (a, b) => a <= b,
        '>': (a, b) => a > b,
        '>=': (a, b) => a >= b,
      };
      var [depName, operator, depValue] = dependency.split(',');

      var fieldValue = isOnLoad ? values[name] || '' : e.target.value;

      fieldValue = parseInt(fieldValue) || fieldValue;
      depValue = parseInt(depValue) || depValue;

      if (typeof depValue === 'string' && depValue.includes('|')) {
        let depValues = depValue.split('|');

        var isRevealed = depValues.find((value) =>
          operators[operator](fieldValue, value)
        );
      } else {
        var isRevealed = operators[operator](fieldValue, depValue);
      }

      let method = isRevealed ? 'add' : 'remove';
      let hiddenInput = inputEl.current?.nextSibling;

      if (hiddenInput) {
        let hiddenField = hiddenInput.closest('.-is-dependent-field');

        if (hiddenField) {
          hiddenField.classList[method]('-is-revealed');
        }
      }
    }
  };

  useEffect(() => {
    revealDependentField(null, true);
    optKeys =
      optKeys && randomizeOptions
        ? optKeys.slice(0, 1).concat(shuffle(optKeys.slice(1)))
        : optKeys;
    setOptKeys(optKeys);

    if (onMount) {
      new Function(
        'opts',
        'setOptKeys',
        'setValues',
        'values',
        'name',
        onMount
      )(opts, setOptKeys, setValues, values, name);
    }
  }, []);

  let Wrapper = label ? 'label' : 'div';

  const El = values ? Field : 'select';

  return (
    <Wrapper
      ref={inputEl}
      className={classnames({
        'field-2': !!label,
        select: true,
        [className]: className,
        '-is-dependent-field': isDependentField,
      })}
      onChange={onChange}
    >
      {label && <span className="field_label">{label}</span>}
      <El
        as="select"
        className={classnames({
          [innerClassName]: innerClassName,
          '-error': errors?.[name] && touched?.[name],
          '-touched': touched?.[name],
        })}
        name={name}
        tabIndex={!isActive || isDependentField ? -1 : undefined}
        required={isRequired}
        onChange={(e) => {
          formRef?.current
            ?.querySelector(`[name=${name}]`)
            .classList.add('-touched');
          revealDependentField(e);
        }}
        onClick={() => {
          if (name === 'schedule_demo_timezone') {
            amplitude.getInstance().logEvent(`Click - Timezone Selector `);
          }
        }}
      >
        {optKeys &&
          optKeys.map((key, i) => (
            <option value={key} key={i}>
              {opts[key]}
            </option>
          ))}
      </El>

      {showError && (
        <ErrorMessage
          name={name}
          className="form_errorMessage"
          component="div"
        />
      )}
    </Wrapper>
  );
};

export default Select;
