import React from 'react';
import loadable from '@loadable/component';
import ComponentPaths from '../cache/component-paths.json';
import Animation from '../components/basic/Animation';
import Button from '../components/basic/Button';
import { CodeSnippet } from '../components/basic/CodeSnippet';
import CsMain from '../components/customer-spotlight/CsMain';
import CsQuote from '../components/customer-spotlight/CsQuote';
import CsInnerCta from '../components/customer-spotlight/CsInnerCta';
import GlossaryGenericCta from '../components/glossary/GlossaryGenericCta';
import GlossaryDarkImageCta from '../components/glossary/GlossaryDarkImageCta';
import GlossaryInlineTipCta from '../components/glossary/GlossaryInlineTipCta';
import GlossaryDisclaimer from '../components/glossary/GlossaryDisclaimer';
import GlossaryLeadInText from '../components/glossary/GlossaryLeadInText';
import DatePicker from '../components/basic/Form/DatePicker';
import EmployeeCostCalculator from '../components/prescriptive/complex/EmployeeCostCalculator';
import Field from '../components/basic/Form/Field';
import Field2 from '../components/basic/Form/Field2';
import Fields from '../components/basic/Form/Fields';
import Form2 from '../components/basic/Form/Form2';
import Gsap from '../components/basic/Gsap';
import Heading from '../components/basic/Heading';
import Html from '../components/basic/Html';
import IfElse from '../components/basic/IfElse';
import Icon from '../components/basic/Icon';
import Image from '../components/basic/Image';
import List from '../components/basic/List';
import Radios from '../components/basic/Form/Radios';
import Paragraph from '../components/basic/Paragraph';
import Select from '../components/basic/Form/Select';
import Steps from '../components/basic/Form/Steps';
import Submit from '../components/basic/Form/Submit';
import Target from '../components/basic/Target';
import Toggler from '../components/basic/Toggler';
import Trigger from '../components/basic/Trigger';
import Video from '../components/basic/Video';
import Wrapper from '../components/basic/Wrapper';
import WrapperClient from '../components/basic/WrapperClient';
import ErrorBoundary from '../components/prescriptive/ErrorBoundary';

const PassThrough = (data) => data?.children;

// @NOTE: Load component here if needed before page load
const components = {
  Animation,
  Button,
  DatePicker,
  CodeSnippet,
  CsMain,
  CsQuote,
  CsInnerCta,
  GlossaryGenericCta,
  GlossaryDarkImageCta,
  GlossaryInlineTipCta,
  GlossaryDisclaimer,
  GlossaryLeadInText,
  EmployeeCostCalculator,
  Field,
  Field2,
  Fields,
  Form2,
  Gsap,
  Heading,
  Html,
  IfElse,
  Icon,
  Image,
  List,
  Radios,
  Paragraph,
  PassThrough,
  Select,
  Steps,
  Target,
  Toggler,
  Trigger,
  Submit,
  Video,
  Wrapper,
  WrapperClient,
};

const componentifyWordpressName = (name) => {
  const sanitizedName = name?.replace(/rgb\/|core\//, '') || '';
  const camelCasedName = sanitizedName.replace(/-(.)/g, function (
    match,
    group1
  ) {
    return group1.toUpperCase();
  });
  return camelCasedName[0].toUpperCase() + camelCasedName.slice(1);
};

const interactiveComponents = {
  Animation: true,
  Carousel: true,
  CountUp: true,
  CircularProgressbar: true,
  Data: true,
  EmployeeCostCalculator: true,
  FixedLottieProgression: true,
  FixedSectionHeader: true,
  Form2: true,
  Gsap: true,
  ImageScrubber: true,
  Map: true,
  PeoPicker: true,
  PeoPicker2: true,
  // 'Recipes': true,
  Slider: true,
  // 'Target': true,
  // 'Trigger': true,
  Toggler: true,
  Video: true,
  Waypoint: true,
  WrapperClient: true,
  Wistia: true,
};

const getLoadableComponent = (Name) => {
  // try catch here to avoid bringing entire pages down
  try {
    return loadable(() => import(`../components/${ComponentPaths[Name]}`));
  } catch (err) {
    console.error(`Failed to load ${Name} through Loadable.`);
  }
  return PassThrough;
};

const shouldHandleAsPassthrough = (Name) => {
  // safeguard to prevent pages from crashing if a component is missed...
  if (!components[Name] && !ComponentPaths[Name]) {
    return true;
  }
  // these should have been handled in the BE, if they slipped through, lets just pass through.
  return [
    'Reusable',
    'Reusable2',
    'Defaults',
    'Repeater',
    'Duplicator',
  ].includes(Name);
};

export const parseData = (data, locale) => {
  // we want to block out content accurately with the pageLocale
  const allowContentLocale = locale;

  return data.map((datum, index) => {
    if (!datum?.name) {
      return null;
    }
    const children = datum.blocks || datum.innerBlocks;

    let Name = componentifyWordpressName(datum.name);

    if (shouldHandleAsPassthrough(Name)) {
      Name = 'PassThrough';
    }

    if (Name === 'Wrapper' && datum.attributes.onMount) {
      Name = 'WrapperClient';
    }

    const Component = components[Name] || getLoadableComponent(Name);
    if (!Component) {
      console.log(`CAN'T FIND ${Name}!!!`);
    }

    // @TODO Remove buildClassName
    if (!datum.attributes) {
      datum.attributes = {};
    }

    datum.attributes.data = datum.attributes.data || {};

    let result;

    // blocks should always be blocked based on page locale
    if (
      datum.attributes.locales &&
      !datum.attributes.locales.includes(allowContentLocale)
    ) {
      return null;
    }

    if (children && children.length) {
      result = (
        <Component {...datum} key={index} locale={locale}>
          {children.length && parseData(children, locale)}
        </Component>
      );
    } else {
      result = <Component {...datum} key={index} locale={locale} />;
    }

    if (interactiveComponents[Name]) {
      result = (
        <ErrorBoundary name={Name} key={index}>
          {result}
        </ErrorBoundary>
      );
    }

    return result;
  });
};

const SectionBuilder = ({ blocks, locale }) => {
  return <>{parseData(blocks, locale)}</>;
};

export default SectionBuilder;
